.ant-spin-container {
  @apply h-full;
}
.ant-form-item {
  @apply mb-1;
}

/*.anticon {
  @apply align-baseline;
}*/

.ant-card-body {
  @apply h-full;
  padding: 8px !important;
}

.ant-select-selection-item {
  @apply font-medium;
}

.ant-modal-content {
  .bg-transparent & {
    @apply h-full;
    background-color: transparent;
  }
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-error::before {
  @apply bg-red-500;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active).ant-steps-item-error
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon {
  @apply border-red-500;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active).ant-steps-item-error
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon
  .ant-steps-icon,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active).ant-steps-item-error
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active).ant-steps-item-error
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-subtitle,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active).ant-steps-item-error
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-description {
  @apply text-red-500;
}

.ant-typography {
  margin-bottom: 0 !important;
}

.ant-collapse-content-box {
  padding: 4px !important;
}

.ant-switch {
  @apply bg-gradient-to-r from-gray to-gray;
}

.ant-switch-checked {
  background: #8cbc3f !important;
}

.ant-avatar-string {
  position: unset !important;
}

.ng-untouched {
  width: 100%;
}

/* You can add global styles to this file, and also import other style files */

@use "./assets/ag-grid";
@use "./assets/ant";
@use "./assets/app";

@tailwind base;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700");

$gray-dark: #2a2a2a !default;
$gray: #53535f !default;
$gray-light: #72849a !default;
$gray-lighter: #ededed !default;
$gray-lightest: #f1f2f3 !default;

$mantis: #8cbc3f !default;
$carrot-orange: #f68c21 !default;
$tangerine-yellow: #ffcb05 !default;
$red: #de4436 !default;

$font-family:
  "Poppins",
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  "PingFang SC",
  "Hiragino Sans GB",
  "Microsoft YaHei",
  "Helvetica Neue",
  Helvetica,
  Arial,
  sans-serif;

body {
  font-family: $font-family;
  font-weight: 500;
}

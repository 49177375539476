.bg-polka {
  background-color: #d2d6e0;
  opacity: 0.8;
  background-image: radial-gradient(#64862d 0.5px, #d2d6e0 0.5px);
  background-size: 10px 10px;
}

.container {
  @apply size-full flex flex-col overflow-y-auto;
}

.sub-container {
  @apply flex-1 p-1 bg-white;
}

.display-wrapper:not(:empty) + .default {
  @apply hidden;
}

.simple-table {
  @apply border-collapse w-full text-sm;
}

.simple-table th,
.simple-table td {
  @apply border p-1 break-all;
}

.tmp-drawer {
  @apply absolute top-0 right-0 pb-0;
}
.tmp-drawer .ant-modal-content {
  @apply min-h-screen;
}

// -------- import official less file -----------
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.compact.less";

// -------- override less variables -----------
@primary-color: #8cbc3f;
@info-color: #3f87f5;
@success-color: #8cbc3f;
@warning-color: #f68c21;
@error-color: #de4436;

@font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB",
"Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;


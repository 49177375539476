.ag-root-wrapper {
  border-radius: 0 !important;
}

.no-min-height .ag-layout-auto-height .ag-center-cols-viewport {
  min-height: unset !important;
}

.ag-details-row .ag-layout-auto-height .ag-center-cols-viewport {
  min-height: unset !important;
}

.ag-details-row {
  @apply p-2 bg-gainsboro-100/75 #{!important};
}

.ag-rich-select-list {
  min-width: 150px !important;
}

.ag-editable-cell {
  @apply cursor-text inline-block transition-all border border-transparent hover:border-mantis-500 hover:before:absolute hover:before:right-0.5 hover:before:top-0.5 hover:before:content-pen;
}

.ag-warning-cell {
  @apply cursor-text inline-block transition-all border border-red-500 hover:border-mantis-500 focus:border-0 before:absolute before:right-0.5 before:top-0.5 before:content-exclamation hover:before:content-pen #{!important};
}

.ag-cell-inline-editing {
  @apply hover:before:content-none #{!important};
}
